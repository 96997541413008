import { Routes, Route, RoutesProps } from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoute from "./ProtectedRoute";
import Integration from "../components/Integration/Integration";
import AddSMSTemplates from "../components/SMS/Templates/Add/Add";
// import CreativeAiFormat from "../components/AI-Creatives/Post/CreativeAiBase";
import Setting from "../components/Settings/Setting";
import socketIO from "socket.io-client";
import AiGeneratedImages from "../components/AI-Creatives/Post/ai-generatedImage";
import AddRule from "../components/HelpDesk/ChatAutomation/Add/AddRule";
import { AccessDenied } from "../components/Common/AccessDenied/AccessDenied";
import ConnectedOperators from "../components/Integration/Operators/List/List";

let app_type = localStorage.getItem("atype");

const socket = socketIO("https://ws.webmaxy.co", { transports: ["websocket"] });

const Main = React.lazy(() => import("../components/Main"));
const ConnectedSocialChannels = React.lazy(
  () => import("../components/Social/ConnectedChannels/List")
);

const AddPost = React.lazy(() => import("../components/Social/Post/Add"));

const Calendar = React.lazy(
  () => import("../components/Social/Calendar/calendar")
);

const AiPlannerCardView = React.lazy(
  () => import("../components/Social/Calendar/AiSocialPost/AiPlannerCardView")
);
const KpiDashboard = React.lazy(
  () => import("../components/KpiDashboard/KpiDashboard")
);
const CommonList = React.lazy(
  () => import("../components/Contacts/ContactList/List/List")
);
const AuthRedirect = React.lazy(
  () => import("../components/AuthRedirect/AuthRedirect")
);
const ListDetails = React.lazy(
  () => import("../components/Contacts/ContactList/Details/Details")
);
const Leads = React.lazy(
  () => import("../components/Contacts/Leads/List/List")
);
const Customers = React.lazy(
  () => import("../components/Contacts/Leads/List/List")
);
const OrdersList = React.lazy(
  () => import("../components/Contacts/Orders/Orders")
);
const RestaurantOrders = React.lazy(
  () => import("../components/Contacts/Restaurant-Orders/RestaurantOrders")
);
const SegmentList = React.lazy(
  () => import("../components/Contacts/Segment/List/List")
);
const ListAtributes = React.lazy(
  () => import("../components/Contacts/ContactList/ListAttribute/List")
);
const UserDetails = React.lazy(
  () => import("../components/Contacts/UserProfile/UserDetails")
);
const ManageCampaign = React.lazy(
  () => import("../components/Email/Campaign/List/List")
);
const AddCampaign = React.lazy(
  () => import("../components/Email/Campaign/Add/Add")
);
const ManageSender = React.lazy(
  () => import("../components/Email/Sender/List/List")
);
const EmailTemplates = React.lazy(
  () => import("../components/Email/Templates/List/List")
);
const SmsTemplate = React.lazy(
  () => import("../components/SMS/Templates/List/List")
);
const SMSCampaign = React.lazy(
  () => import("../components/SMS/Campaign/List/List")
);
const MarketingLinks = React.lazy(
  () => import("../components/Tools/Marketing Links/List/MarketingLinks")
);
const MarketingAutomation = React.lazy(
  () => import("../components/Automation/List/List")
);
const WhatsAppCampaigns = React.lazy(
  () => import("../components/WhatsApp/Campaign/List/List")
);
const AddWhatsAppCampaigns = React.lazy(
  () => import("../components/WhatsApp/Campaign/Add/Add")
);
const Ads = React.lazy(() => import("../components/Retargating/List/List"));
const WhatsAppTemplate = React.lazy(
  () => import("../components/WhatsApp/Templates/List/List")
);
const WhatsAppCollections = React.lazy(
  () => import("../components/WhatsApp/Collections/List")
);
const WhatsAppConfiguration = React.lazy(
  () => import("../components/WhatsApp/Setting/List")
);
const WhatsAppProducts = React.lazy(
  () => import("../components/WhatsApp/Collections/Products/List")
);
const WhatsAppProductView = React.lazy(
  () => import("../components/WhatsApp/Collections/Products/View")
);
const EmailGateways = React.lazy(
  () => import("../components/Email/Gateways/List/List")
);
const MarketingCampaign = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/Marketing/List/List"
    )
);
const CampaignReport = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/CampaignReport/List/List"
    )
);
const AddEmailTemplates = React.lazy(
  () => import("../components/Email/Templates/Add/Add")
);
const WhatsAppAccounts = React.lazy(
  () => import("../components/WhatsApp/Gateways/List/List")
);
const SegmentDetail = React.lazy(
  () => import("../components/Contacts/Segment/Detail/Detail")
);
const SMSConnectedGateways = React.lazy(
  () => import("../components/SMS/Gateways/List/List")
);
const MarketingCampaignDetails = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/Marketing/Details/MarketingCampaignDetails"
    )
);
const Email = React.lazy(
  () => import("../components/Email/Gateways/EmailGateway/EmailGateway")
);
const ConnectedChannels = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/ConnectedChannels/ConnectedChannels"
    )
);
const MarketingChannels = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/CampaignChannels/MarketingChannels"
    )
);
const PredefinedEmailTemplates = React.lazy(
  () =>
    import(
      "../components/Email/Templates/PredefinedEmailTemplates/PredefinedEmailTemplates"
    )
);
const Profile = React.lazy(() => import("../components/Profile/Profile"));
const SmsGateway = React.lazy(
  () => import("../components/SMS/Gateways/SmsGateway/SmsGateway")
);
const PostAnalysis = React.lazy(
  () => import("../components/Social/PostAnalysis/PostAnalysis")
);
const Brands = React.lazy(() => import("../components/Brands/Brands"));
const TeamMember = React.lazy(
  () => import("../components/TeamMember/TeamMember")
);
const ChatAutomation = React.lazy(
  () => import("../components/HelpDesk/ChatAutomation/ChatAutomation")
);
const QuickReply = React.lazy(
  () => import("../components/HelpDesk/QuickReply/QuickReply")
);
const ConversionDashbord = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/ConversionDashboard/ConversionDashbord"
    )
);
const CampaignTracker = React.lazy(
  () => import("../components/MarketingInsights/CampaignTracker/List/List")
);
const ExistingList = React.lazy(
  () => import("../components/Contacts/ContactList/Existing/List")
);
const AddSMSCampaigns = React.lazy(
  () => import("../components/SMS/Campaign/Add/Add")
);
const AddWhatsappTemplates = React.lazy(
  () => import("../components/WhatsApp/Templates/Add/Add")
);
const Reservations = React.lazy(
  () => import("../components/Reservations/List/List")
);
const TreeView = React.lazy(
  () => import("../components/WhatsApp/Collections/TreeView")
);
const PredefinedWhatsappTemplates = React.lazy(
  () =>
    import(
      "../components/WhatsApp/Templates/PredefinedWhatsappTemplates/PredefinedWhatsappTemplates"
    )
);
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const AddBrand = React.lazy(
  () => import("../components/Brands/AddBrand/AddBrand")
);
const WixRedirect = React.lazy(
  () => import("../components/WixRedirect/WixRedirect")
);
const AccountRedirect = React.lazy(
  () =>
    import("../components/MarketingInsights/AccountRedirect/AccountRedirect")
);
const SmsCampaignReport = React.lazy(
  () => import("../components/SMS/Campaign/Report/Report")
);
const MySubscription = React.lazy(
  () => import("../components/Subscription/Subscription")
);
const SocialChannels = React.lazy(
  () => import("../components/Social/SocialChannels/Channels")
);
const CreateGoogleAdRule = React.lazy(
  () =>
    import(
      "../components/Ads-Automation/GoogleAds/CreateGoogleAdRule/CreateGoogleAdRule"
    )
);
const Chat = React.lazy(() => import("../components/HelpDesk/Chat/Chat"));
const AdsRule = React.lazy(
  () => import("../components/Ads-Automation/AdsRule/AdsRule")
);
// const CreateAdsRules = React.lazy(
//   () => import("../components/Ads-Automation/CreateAdsRules/CreateAdsRules")
// );
const CreativeAiList = React.lazy(
  () => import("../components/AI-Creatives/List/List")
);
const HelpDeskDashboard = React.lazy(
  () => import("../components/HelpDesk/Dashboard/HelpDeskDashboard")
);
const CreativeAiBase = React.lazy(
  () => import("../components/AI-Creatives/Post/CreativeAiBase")
);
const AutomationReport = React.lazy(
  () => import("../components/Automation/Report/Report")
);
const TopStrategy = React.lazy(
  () => import("../components/Top-strategy/Strategy")
);
const AIMarketer = React.lazy(
  () => import("../components/Ai-Marketer/AiMarketer")
);
const CreateFacebookAdRule = React.lazy(
  () =>
    import(
      "../components/Ads-Automation/FacebookAds/CreateFacebookAdRule/CreateFacebookAdRule"
    )
);
const ConnectedList = React.lazy(
  () => import("../components/WhatsApp/Facebook/List")
);
const NPS = React.lazy(() => import("../components/Loyalty/NPS/Nps"));
const RetensionList = React.lazy(
  () => import("../components/Retension/List/List")
);
const RetensionReport = React.lazy(
  () => import("../components/Retension/Report/Report")
);
const TopCreativesList = React.lazy(
  () => import("../components/Top-Creatives/List/List")
);

const Branding = React.lazy(
  () => import("../components/Loyalty/Branding/Branding")
);
const GoogleSheet = React.lazy(() => import("../components/GoogleSheet/List"));

const ShopifyIntegration = React.lazy(
  () => import("../components/ShopifyIntegration/ShopifyIntegration")
);
const TopCreativesReport = React.lazy(
  () => import("../components/Top-Creatives/Report/Report")
);

const NpsUserResponse = React.lazy(
  () => import("../components/Loyalty/NPS/User-response")
);

const RFM = React.lazy(() => import("../components/RFM/RFM"));

const CRM = React.lazy(() => import("../components/CRM/CRMList"));

const Shopify = React.lazy(
  () => import("../components/ShopifyRedirect/shopify")
);

// onboarding flow

const WhyConnect = React.lazy(() => import("../pages/Onboarding/WhyConnect"));
const SetupMenu = React.lazy(() => import("../pages/Onboarding/SetupMenu"));

// WhatsappTemplate flow
const WhtasAppTemplates = React.lazy(
  () => import("../pages/DesignsUi/WhatsAppCampaigns/WhtasAppTemplates")
);
const WhatsAppTemplateBase = React.lazy(
  () => import("../components/WhatsApp/Templates/NewList/BaseList")
);
const WhatsAppPlayground = React.lazy(
  () => import("../components/WhatsApp/Playground/playground")
);
const Billing = React.lazy(() => import("../components/Billing/Billing"));
const FlowList = React.lazy(
  () => import("../components/WhatsApp/Flows/List/List")
);

const MenuConfiguration = React.lazy(
  () => import("../components/MenuConfiguration/MenuConfiguration")
);
const Events = React.lazy(
  () => import("../components/MenuConfiguration/Events/List")
);
const SubscriptionEx = React.lazy(
  () => import("../components/Common/SubscriptionEx/SubscriptionEx")
);


const Navigation = () => {
  useEffect(() => {
    function onConnect() {
      socket.emit("setId", { id: localStorage.getItem("app_id") ?? "", user_id: localStorage.getItem("loggedInUserId") ?? "" });
    }
    function onDisconnect() { }
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const EmailCampaignReport = React.lazy(
    () => import("../components/Email/Campaign/Report/Report")
  );
  const EmailSendyCampaignReport = React.lazy(
    () => import("../components/Email/Campaign/SendyReport/SendyReport")
  );
  const WhatsappCampaignReport = React.lazy(
    () => import("../components/WhatsApp/Campaign/Report/Report")
  );
  const DetailReport = React.lazy(
    () => import("../components/Automation/DetailReport/DetailReport")
  );
  const MaretingAutomationAdd = React.lazy(
    () => import("../components/Automation/Add/Add")
  );
  const RewardPoint = React.lazy(
    () => import("../components/Loyalty/RewardPoints/RewardPoint")
  );
  const RewardDashboard = React.lazy(
    () => import("../components/Loyalty/Dashboard/Dashboard")
  );
  const WhatsappConnect = React.lazy(
    () => import("../components/Onboarding/WhyConnect")
  );
  const WhatsappSetupMenu = React.lazy(
    () => import("../components/Onboarding/SetupMenu")
  );
  const ConnectShopify = React.lazy(
    () => import("../components/Onboarding/ConnectShopify")
  );
  const ConnectCatlog = React.lazy(
    () => import("../components/Onboarding/ConnectCatlogWithMeta")
  );
  const ConnectWhatsappAccount = React.lazy(
    () => import("../components/Onboarding/ConnectWhatsapp")
  );
  const FlowList = React.lazy(
    () => import("../components/WhatsApp/Flows/List/FlowList")
  );

  const Rating = React.lazy(
    () => import("../components/MenuConfiguration/Ratings/List")
  );

  const BotAutomationAdd = React.lazy(
    () => import("../components/BotAutomation/Add/Add")
  );

  return (
    <>
      <div id="main-wraper">
        <Routes>

          <Route path="/auth/redirect" element={<AuthRedirect />} />

          <Route
            path="/setup"
            element={
              <Suspense fallback={<></>}>
                <AddBrand />
              </Suspense>
            }
          />

          <Route
            path="/shopify-integration"
            element={
              <Suspense fallback={<></>}>
                <ShopifyIntegration />
              </Suspense>
            }
          />
          <Route
            path="/wix/install"
            element={
              <Suspense fallback={<></>}>
                <WixRedirect />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main socket={socket} />}
              />
            }
          >
            <Route
              path="/subscription-expired"
              element={
                <Suspense fallback={<></>}>
                  <SubscriptionEx />
                </Suspense>
              }
            />
            <Route
              path="/list"
              element={
                <Suspense fallback={<></>}>
                  <CommonList />
                </Suspense>
              }
            />
            <Route
              path="/post-analysis"
              element={
                <Suspense fallback={<></>}>
                  <PostAnalysis />
                </Suspense>
              }
            />
            <Route
              path="/list/all-users/:id"
              element={
                <Suspense fallback={<></>}>
                  <ExistingList />
                </Suspense>
              }
            />
            <Route
              path="/list-users/:id"
              element={
                <Suspense fallback={<></>}>
                  <ListDetails />
                </Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <Suspense fallback={<></>}>
                  <Profile />
                </Suspense>
              }
            />
            <Route
              path="/leads"
              element={
                <Suspense fallback={<></>}>
                  <Leads socket={socket} />
                </Suspense>
              }
            />
            <Route
              path="/branding"
              element={
                <Suspense fallback={<></>}>
                  <Branding />
                </Suspense>
              }
            />
            <Route
              path="/customers"
              element={
                <Suspense fallback={<></>}>
                  <Customers />
                </Suspense>
              }
            />
            <Route
              path="/list-attributes"
              element={
                <Suspense fallback={<></>}>
                  <ListAtributes />
                </Suspense>
              }
            />
            <Route
              path="/user-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <UserDetails />
                </Suspense>
              }
            />
            {app_type != "RESTAURANT" && app_type != 'B2B' ? (
              <Route
                path="/orders"
                element={
                  <Suspense fallback={<></>}>
                    <OrdersList />
                  </Suspense>
                }
              />
            ) : (
              <Route
                path="orders"
                element={
                  <Suspense fallback={<></>}>
                    <RestaurantOrders />
                  </Suspense>
                }
              />
            )}
            <Route
              path="/segments"
              element={
                <Suspense fallback={<></>}>
                  <SegmentList />
                </Suspense>
              }
            />
            <Route
              path="/google-sheet"
              element={
                <Suspense fallback={<></>}>
                  <GoogleSheet />
                </Suspense>
              }
            />
            <Route
              path="/email"
              element={
                <Suspense fallback={<></>}>
                  <ManageCampaign />
                </Suspense>
              }
            />
            <Route
              path="/email/edit-email/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddCampaign />
                </Suspense>
              }
            />

            <Route
              path="/email/add-email"
              element={
                <Suspense fallback={<></>}>
                  <AddCampaign />
                </Suspense>
              }
            />
            <Route
              path="/integrations"
              element={
                <Suspense fallback={<></>}>
                  <Integration />
                </Suspense>
              }
            />
            <Route
              path="/connected-operators"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedOperators />
                </Suspense>
              }
            />
            <Route
              path="/email-gateways"
              element={
                <Suspense fallback={<></>}>
                  <Email />
                </Suspense>
              }
            />
            <Route
              path="/sender"
              element={
                <Suspense fallback={<></>}>
                  <ManageSender />
                </Suspense>
              }
            />
            <Route
              path="/email-templates"
              element={
                <Suspense fallback={<></>}>
                  <EmailTemplates />
                </Suspense>
              }
            />

            <Route
              path="/email-templates/edit-email-template/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddEmailTemplates />
                </Suspense>
              }
            />

            <Route
              path="/email-templates/add-email-template-default/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddEmailTemplates />
                </Suspense>
              }
            />

            <Route
              path="/connected-gateways"
              element={
                <Suspense fallback={<></>}>
                  <SMSConnectedGateways />
                </Suspense>
              }
            />
            <Route
              path="/sms-templates"
              element={
                <Suspense fallback={<></>}>
                  <SmsTemplate />
                </Suspense>
              }
            />
            <Route
              path="/marketing-links"
              element={
                <Suspense fallback={<></>}>
                  <MarketingLinks />
                </Suspense>
              }
            />
            <Route
              path="/marketing-automation"
              element={
                <Suspense fallback={<></>}>
                  <MarketingAutomation />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-campaign"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppCampaigns />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-template"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppTemplateBase />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-collections"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppCollections />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp/configuration"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppConfiguration />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-collections/whatsapp-products/:id"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppProducts />
                </Suspense>
              }
            />
            <Route
              path="/:tenant_id/product/:product_id"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppProductView />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-template/add-whatsapp-template"
              element={
                <Suspense fallback={<></>}>
                  <AddWhatsappTemplates />
                </Suspense>
              }
            />
            <Route
              path="/reservations"
              element={
                <Suspense fallback={<></>}>
                  <Reservations />
                </Suspense>
              }
            />
            <Route
              path="/categories"
              element={
                <Suspense fallback={<></>}>
                  <TreeView />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-templates/default-whatsapp-template"
              element={
                <Suspense fallback={<></>}>
                  <PredefinedWhatsappTemplates />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-template/add-whatsapp-template-default/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddWhatsappTemplates />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-campaign/add-whatsapp-campaign"
              element={
                <Suspense fallback={<></>}>
                  <AddWhatsAppCampaigns />
                </Suspense>
              }
            />
            {/* <Route
              path="/whatsapp-campaign/add-whatsapp-campaign/:templateId/:userId"
              element={
                <Suspense fallback={<></>}>
                  <AddWhatsAppCampaigns />
                </Suspense>
              }
            /> */}
            <Route
              path="/marketing-campaign/marketing"
              element={
                <Suspense fallback={<></>}>
                  <MarketingCampaign />
                </Suspense>
              }
            />
            <Route
              path="/campaign-reports"
              element={
                <Suspense fallback={<></>}>
                  <CampaignReport />
                </Suspense>
              }
            />
            <Route
              path="/kpi-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <KpiDashboard />
                </Suspense>
              }
            />
            <Route
              path="/connected-email-gateways"
              element={
                <Suspense fallback={<></>}>
                  <EmailGateways />
                </Suspense>
              }
            />
            <Route
              path="/connected-whatsapp-accounts"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppAccounts />
                </Suspense>
              }
            />
            <Route
              path="/meta-ads"
              element={
                <Suspense fallback={<></>}>
                  <Ads />
                </Suspense>
              }
            />
            <Route
              path="/google-ads"
              element={
                <Suspense fallback={<></>}>
                  <Ads />
                </Suspense>
              }
            />
            <Route
              path="/sms-campaign"
              element={
                <Suspense fallback={<></>}>
                  <SMSCampaign />
                </Suspense>
              }
            />
            <Route
              path="/email-templates/add-email-templates"
              element={
                <Suspense fallback={<></>}>
                  <AddEmailTemplates />
                </Suspense>
              }
            />
            <Route
              path="/segments/add-segment"
              element={
                <Suspense fallback={<></>}>
                  <SegmentDetail />
                </Suspense>
              }
            />
            <Route
              path="/segments/edit-segment/:id"
              element={
                <Suspense fallback={<></>}>
                  <SegmentDetail />
                </Suspense>
              }
            />

            <Route
              path="/marketing/marketing-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <MarketingCampaignDetails />
                </Suspense>
              }
            />
            <Route
              path="connected-channels"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedChannels />
                </Suspense>
              }
            />
            <Route
              path="accounts"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedChannels />
                </Suspense>
              }
            />
            <Route
              path="campaign-tracker"
              element={
                <Suspense fallback={<></>}>
                  <CampaignTracker />
                </Suspense>
              }
            />

            <Route
              path="marketing-campaign/marketing-channel"
              element={
                <Suspense fallback={<></>}>
                  <GoogleOAuthProvider clientId="843653690200-pdl90i2kpjtfe4o2mj4a75k7i20dec24.apps.googleusercontent.com">
                    <MarketingChannels />
                  </GoogleOAuthProvider>
                </Suspense>
              }
            />

            <Route
              path="/email-templates/default-email-template"
              element={
                <Suspense fallback={<></>}>
                  <PredefinedEmailTemplates />
                </Suspense>
              }
            />

            <Route
              path="/sms-gateways"
              element={
                <Suspense fallback={<></>}>
                  <SmsGateway />
                </Suspense>
              }
            />

            <Route
              path="/my-brand"
              element={
                <Suspense fallback={<></>}>
                  <Brands />
                </Suspense>
              }
            />

            <Route
              path="/team"
              element={
                <Suspense fallback={<></>}>
                  <TeamMember />
                </Suspense>
              }
            />
            <Route
              path="/social-campaign/schedule"
              element={
                <Suspense fallback={<></>}>
                  <Calendar />
                </Suspense>
              }
            />

            <Route
              path="/ai-planner-templates"
              element={
                <Suspense fallback={<></>}>
                  <AiPlannerCardView />
                </Suspense>
              }
            />

            <Route
              path="/chat-automation"
              element={
                <Suspense fallback={<></>}>
                  <ChatAutomation />
                </Suspense>
              }
            />
            <Route
              path="/quick-reply"
              element={
                <Suspense fallback={<></>}>
                  <QuickReply />
                </Suspense>
              }
            />

            <Route
              path="/add-sms-template"
              element={
                <Suspense fallback={<></>}>
                  <AddSMSTemplates />
                </Suspense>
              }
            />

            <Route
              path="/sms-templates/edit-sms-template/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddSMSTemplates />
                </Suspense>
              }
            />

            <Route
              path="/conversion"
              element={
                <Suspense fallback={<></>}>
                  <ConversionDashbord />
                </Suspense>
              }
            />
            <Route
              path="/add-sms-campaign"
              element={
                <Suspense fallback={<></>}>
                  <AddSMSCampaigns />
                </Suspense>
              }
            />
            <Route
              path="/sms-campaign/edit-sms-campaign/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddSMSCampaigns />
                </Suspense>
              }
            />
            <Route
              path="/social-campaign/channels"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedSocialChannels />
                </Suspense>
              }
            />
            <Route
              path="/social-campaign/social-accounts"
              element={
                <Suspense fallback={<></>}>
                  <SocialChannels />
                </Suspense>
              }
            />
            <Route
              path="/social-campaign/schedule/post"
              element={
                <Suspense fallback={<></>}>
                  <AddPost />
                </Suspense>
              }
            />
            <Route
              path="/social-campaign/schedule/edit-post/:id"
              element={
                <Suspense fallback={<></>}>
                  <AddPost />
                </Suspense>
              }
            />

            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="/email-dashboard"
              key="email-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"email-dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-dashboard"
              key="whatsapp-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"whatsapp-dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="/social-dashboard"
              key="social-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"social-dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="/sms-dashboard"
              key="sms-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"sms-dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="/audience-dashboard"
              key="audience-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard dType={"audience-dashboard"} />
                </Suspense>
              }
            />

            <Route
              path="linkedin/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"LINKEDIN"} />
                </Suspense>
              }
            />

            <Route
              path="instagram/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"INSTAGRAM"} />
                </Suspense>
              }
            />

            <Route
              path="twitter/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"TWITTER"} />
                </Suspense>
              }
            />
            <Route
              path="amazon/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"AMAZON"} />
                </Suspense>
              }
            />

            <Route
              path="/sms-campaign/sms-campaign-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <SmsCampaignReport />
                </Suspense>
              }
            />
            <Route
              path="/my-subscription"
              element={
                <Suspense fallback={<></>}>
                  <MySubscription />
                </Suspense>
              }
            />
            <Route
              path="/setting"
              element={
                <Suspense fallback={<></>}>
                  <Setting />
                </Suspense>
              }
            />
            <Route
              path="/access-denied"
              element={
                <Suspense fallback={<></>}>
                  <AccessDenied />
                </Suspense>
              }
            />
            {/* <Route
              path="/whatsapp-accounts"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppGateways />
                </Suspense>
              }
            /> */}
            <Route
              path="/ads-automation"
              element={
                <Suspense fallback={<></>}>
                  <GoogleOAuthProvider clientId="843653690200-pdl90i2kpjtfe4o2mj4a75k7i20dec24.apps.googleusercontent.com">
                    <AdsRule />
                  </GoogleOAuthProvider>
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-google-ads-rules"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-google-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/edit-google-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />

            <Route
              path="/ads-automation/create-facebook-ads-rules"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-facebook-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/edit-facebook-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />

            {/* <Route path="*" element={<NotFound />} /> */}

            <Route
              path="/email/email-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <EmailCampaignReport />
                </Suspense>
              }
            />
            <Route
              path="/sendy-dashboard/:id"
              element={
                <Suspense fallback={<></>}>
                  <EmailSendyCampaignReport />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-campaign/whatsapp-campaign-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <WhatsappCampaignReport />
                </Suspense>
              }
            />

            <Route
              path="/chat"
              element={
                <Suspense fallback={<></>}>
                  <Chat socket={socket} />
                </Suspense>
              }
            />
            <Route
              path="/ai-creatives"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiList />
                </Suspense>
              }
            />
            <Route
              path="/ai-creatives/add"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiBase />
                </Suspense>
              }
            />
            <Route
              path="/reward-points"
              element={
                <Suspense fallback={<></>}>
                  <RewardPoint />
                </Suspense>
              }
            />

            <Route
              path="/reward-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <RewardDashboard />
                </Suspense>
              }
            />
            <Route
              path="/work-flow-report/:id"
              element={
                <Suspense fallback={<></>}>
                  <AutomationReport />
                </Suspense>
              }
            />
            <Route
              path="/helpdesk-dashboard"
              element={
                <Suspense fallback={<></>}>
                  <HelpDeskDashboard />
                </Suspense>
              }
            />

            <Route
              path="/workflow-user-report/:id"
              element={
                <Suspense fallback={<></>}>
                  <DetailReport />
                </Suspense>
              }
            />

            <Route
              path="/ai-generated-card/:id"
              element={
                <Suspense fallback={<></>}>
                  <AiGeneratedImages />
                </Suspense>
              }
            />

            <Route
              path="/help-desk/channels"
              element={
                <Suspense fallback={<></>}>
                  <SocialChannels />
                </Suspense>
              }
            />
            <Route
              path="/workflow/:id"
              element={
                <Suspense fallback={<></>}>
                  <MaretingAutomationAdd />
                </Suspense>
              }
            />
            <Route path="/chat-automation/add-rule" element={<AddRule />} />
            <Route
              path="/chat-automation/edit-chat-automation/:id"
              element={<AddRule />}
            />

            <Route
              path="/ai-creatives/edit/:creative_post_id"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiBase />
                </Suspense>
              }
            />

            <Route
              path="/top-strategy"
              element={
                <Suspense fallback={<></>}>
                  <TopStrategy />
                </Suspense>
              }
            />

            <Route
              path="/ai-marketer"
              element={
                <Suspense fallback={<></>}>
                  <AIMarketer />
                </Suspense>
              }
            />

            <Route
              path="/nps"
              element={
                <Suspense fallback={<></>}>
                  <NPS />
                </Suspense>
              }
            />

            <Route
              path="/retention"
              element={
                <Suspense fallback={<></>}>
                  <RetensionList />
                </Suspense>
              }
            />
            <Route
              path="/connect/channels"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedList />
                </Suspense>
              }
            />
            <Route
              path="/retention/:id"
              element={
                <Suspense fallback={<></>}>
                  <RetensionReport />
                </Suspense>
              }
            />

            <Route
              path="/top-creatives"
              element={
                <Suspense fallback={<></>}>
                  <TopCreativesList />
                </Suspense>
              }
            />

            <Route
              path="/top-creatives-report/:id"
              element={
                <Suspense fallback={<></>}>
                  <TopCreativesReport />
                </Suspense>
              }
            />

            <Route
              path="/nps-user-response"
              element={
                <Suspense fallback={<></>}>
                  <NpsUserResponse />
                </Suspense>
              }
            />

            <Route
              path="/rfm"
              element={
                <Suspense fallback={<></>}>
                  <RFM />
                </Suspense>
              }
            />

            <Route
              path="/crm"
              element={
                <Suspense fallback={<></>}>
                  <CRM />
                </Suspense>
              }
            />

            <Route
              path="zoho/callback"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"ZOHO"} />
                </Suspense>
              }
            />

            <Route
              path="shop/auth/callback"
              element={
                <Suspense fallback={<></>}>
                  <Shopify />
                </Suspense>
              }
            />
            {/* Onboarding */}
            <Route
              path="/onboarding/whyconnect/"
              element={
                <Suspense fallback={<></>}>
                  <WhyConnect />
                </Suspense>
              }
            />
            <Route
              path="/onboarding/setupmenu/"
              element={
                <Suspense fallback={<></>}>
                  <SetupMenu />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-campaign/templates/"
              element={
                <Suspense fallback={<></>}>
                  <WhtasAppTemplates />
                </Suspense>
              }
            />
            {/* whatsapp Flow  */}

            <Route
              path="/onboarding/whatsapp-connect"
              element={
                <Suspense fallback={<></>}>
                  <WhatsappConnect />
                </Suspense>
              }
            />
            <Route
              path="/onboarding/setup-menu"
              element={
                <Suspense fallback={<></>}>
                  <WhatsappSetupMenu />
                </Suspense>
              }
            />

            <Route
              path="/onboarding/connect-shopify"
              element={
                <Suspense fallback={<></>}>
                  <ConnectShopify />
                </Suspense>
              }
            />

            <Route
              path="/onboarding/connect-catlog"
              element={
                <Suspense fallback={<></>}>
                  <ConnectCatlog />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-template"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppTemplateBase />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-flows"
              element={
                <Suspense fallback={<></>}>
                  <FlowList />
                </Suspense>
              }
            />

            <Route
              path="/whatsapp-flows/create"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppPlayground />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-flows/edit/:id"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppPlayground />
                </Suspense>
              }
            />
            <Route
              path="/onboarding/connect-whatsapp-account"
              element={
                <Suspense fallback={<></>}>
                  <ConnectWhatsappAccount />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-flows"
              element={
                <Suspense fallback={<></>}>
                  <FlowList />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-flows/create"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppPlayground />
                </Suspense>
              }
            />
            <Route
              path="/whatsapp-flows/edit/:id"
              element={
                <Suspense fallback={<></>}>
                  <WhatsAppPlayground />
                </Suspense>
              }
            />
            <Route
              path="/menu-configuration"
              element={
                <Suspense fallback={<></>}>
                  <MenuConfiguration />
                </Suspense>
              }
            />
            <Route
              path="/events"
              element={
                <Suspense fallback={<></>}>
                  <Events />
                </Suspense>
              }
            />

            <Route
              path="/billing"
              element={
                <Suspense fallback={<></>}>
                  <Billing />
                </Suspense>
              }
            />
            <Route
              path="google/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"GOOGLE_SHEETS"} />
                </Suspense>
              }
            />
            <Route
              path="/menu-configuration"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"GOOGLE_SHEETS"} />
                </Suspense>
              }
            />
            <Route
              path="/rating"
              element={
                <Suspense fallback={<></>}>
                  <Rating />
                </Suspense>
              }
            />

            <Route
              path="/bot-workflow"
              element={
                <Suspense fallback={<></>}>
                  <BotAutomationAdd />
                </Suspense>
              }
            />


          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
